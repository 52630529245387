import { UAParser } from "../../node_modules/ua-parser-js/dist/ua-parser.pack.js";
import anime from '../../node_modules/animejs/lib/anime.js';

var parser = new UAParser();

// by default it takes ua string from current browser's window.navigator.userAgent
var detect = parser.getResult();

//console.log(detect);

var browser = detect.browser.name.replace(/\s+/g, '-').toLowerCase();

var os = detect.os.name.replace(/\s+/g, '-').toLowerCase(); // transform to lower cases and space to hyphen

document.body.classList.add(os); //for os depending style
document.body.classList.add(browser); //for browser depending style


document.getElementById('animation-container').style.opacity = 1;

var vid = document.getElementById('vid'),
duration,
sectionnum = document.getElementById('animation-container').childElementCount,
secoff = 200, //one second offset = scroll distance for one second of video time - default 400
sections = [],
totalHeight,
breaks = [],
transarea = 250, //height of transition area - greater height for longer transitions - default 250
pageoffset = 0, //this is the amount of space at the end of the page AFTER the animation ends
intervaltime = 100, //default 40 - time cycle for animation
stableSpeed = 20, //lower number for higher speed during stable text situation - default 25
elementOffset = 120; //animation offset for text transition up and down - default 120
var frameRate = 25;

var firstLoopID; //interval loop for initial animation before scrolling

/*set manual transition breaks via seconds*/
breaks = [
  0,
  2,
  6.3,
  10.3,
  14.2,
  17.8
];

breaks.forEach(function(item, index, array) {
  array[index] *= secoff;
});

var scrollerHidden = false;
var scroller = document.getElementById('arrow');
var scrollerTop = document.getElementById('arrowtop');
//var bar = document.getElementById('progress');

var initialScroll = false;

function setScroller() {
  if (window.pageYOffset > 30) {
    scroller.classList.add("hidden");
    //scrollerHidden = true;
    scroller.style.zIndex = 1;
    initialScroll = true;
  } else {
    scroller.classList.remove("hidden");
    scroller.style.zIndex = 2;
  }

  if ((window.pageYOffset + window.outerHeight) > (totalHeight - 100)) {
    scrollerTop.classList.add("show");
    vid.style.opacity = 0;
  } else {
    vid.style.opacity = 1;
  }

}

var firstLoop = 3 * secoff // 3 seconds
var loopStarted = false;

function setInitialLoop(){
  var loopindex = 0;
  loopStarted = true;
  if (!firstPass) {
    firstLoopID = setInterval(function (){     
    loopindex += 30;
    //console.log(loopindex);
    vid.currentTime = loopindex / secoff;
    if (loopindex > 600) {
      clearInterval(firstLoopID);
      firstPass = true;
      vid.pause();
      //setInitialLoop();
    }
}, 120);
  }
}

duration = 21;
totalHeight = Math.floor( duration * secoff) + window.outerHeight;
document.body.style.height = totalHeight + pageoffset + 'px';

//vid.addEventListener('loadedmetadata', function(){   

vid.addEventListener('canplay', function(){
  vid.style.opacity = 1;
  if (!loopStarted) {
    setInitialLoop();
  }
});

if (vid.readyState > 3) {
  vid.style.opacity = 1;
  if (!loopStarted) {
    setInitialLoop();
  }
}

vid.pause();
 
/*window.onscroll = function(){
    vid.pause();
};*/


var firstPass = false;

/* MAIN LOOP */
setInterval(function(){
    //console.log(initialScroll);
    let ctime = window.pageYOffset / secoff;
    if((window.pageYOffset > 20) ) {
      if((window.pageYOffset > 200) ) {
        firstPass = true;
      }
      if (ctime < duration) {
        vid.currentTime = (600 + window.pageYOffset) / secoff;
        clearInterval(firstLoopID);
      }
    } 
    else if ( (window.pageYOffset < 21 ) && (firstPass) ) {
      //if scrolling to top is very fast
      vid.currentTime = 3;
    }
    setActiveSection();
    setProgressBar();
    doTransition();
    if (!scrollerHidden) {
      setScroller();
    }
}, intervaltime);

setActiveSection();

window.addEventListener('load', function(){
  for(var i=0; i < sectionnum; i++) {
    sections[i] = document.getElementsByClassName('section-' + i)[0];
  }
});

function setActiveSection() {
  let activeArea = 0;

  breaks.forEach(function(item, index, array) {
    if (item < ( window.pageYOffset ) ) {
      activeArea = index;
    }

    if (index === ( sectionnum - 1 ) ) {
      sections.forEach(function(sec, indexsec, arraysec) {
        if (indexsec === activeArea) {
          sec.classList.add("active");
        } else {
          sec.classList.remove("active");
        }
      });
    }
  });
}

function setProgressBar() {
  var progress = window.pageYOffset / (totalHeight - window.outerHeight);
  //var vwidth = window.innerWidth + 30;
  var vwidth = window.innerWidth;
  var rendwidth = vwidth * progress;
  //bar.style.width = rendwidth + 'px';
}

setProgressBar();

function doTransition() {
  sections.forEach(function(item, index, array) {
    if (item.classList.contains("active")) {
      item.style.visibility = 'visible';
      if ( ( ( breaks[index] + transarea ) > window.pageYOffset) && ( (breaks[index] - transarea) > 0 ) ) {
        item.style.opacity = (window.pageYOffset - breaks[index]) / transarea;
        item.style.transform = "translate3d(0,-" + (((window.pageYOffset - breaks[index]) / transarea) * elementOffset) + "px,0)";
      } else if ((breaks[index + 1] - transarea) < window.pageYOffset) { 
        item.style.opacity = (breaks[index + 1] - window.pageYOffset) / transarea;
        item.style.transform = "translate3d(0,-" + ( ((window.pageYOffset - breaks[index]) / stableSpeed ) + elementOffset + ((1 - ((breaks[index + 1] - window.pageYOffset) / transarea) ) * elementOffset)) + "px,0)";
      } else {
        item.style.opacity = 1;
        item.style.transform = "translate3d(0,-" + (elementOffset + (window.pageYOffset - breaks[index]) / stableSpeed ) + "px,0)";
        //console.log('within stable area');
      }
    } else {
      item.style.opacity = 0;
    }
  });
}

function setArrow(){
  var timeline = anime.timeline({
    autoplay: true,
    direction: "alternate",
    loop: 5
  });

  timeline
  .add({
    targets: ".smallline",
    opacity: {
      value: ["0", "1"],
      duration: 0,
      easing: "linear"
    },
  })
  .add({
    targets: ".smallline",
    d: {
      value: [
        "M39.7984814,60.9769504 L42.9788019,61.0520417 C43.2782833,61.0591129 43.5768146,61.0886026 43.8718913,61.1402634 L45.212452,61.3749633 C45.3504759,61.399128 45.4511719,61.518985 45.4511719,61.6591082 L45.4511719,61.9014591 L45.4511719,62.2749891 C45.4511719,62.4865555 45.2898776,62.6632291 45.0791856,62.682444 L42.2117414,62.9439527 C42.0305667,62.9604757 41.8487342,62.96875 41.6668076,62.96875 L36.623505,62.96875 C36.3984456,62.96875 36.1735644,62.9560871 35.9499277,62.9308213 L33.8223407,62.6904527 C33.6109339,62.6665686 33.4511719,62.4877407 33.4511719,62.2749891 L33.4511719,61.9014591 L33.4511719,61.6591082 C33.4511719,61.5185099 33.5511378,61.3977735 33.6892675,61.3715422 L34.8284649,61.1552045 C35.1527823,61.0936154 35.4816294,61.0588967 35.8116579,61.0514019 L39.1038551,60.9766374 C39.3353686,60.9713798 39.5669728,60.9714842 39.7984814,60.9769504 Z",
        "M39.4511719,1 L46,1 L46,1 L46,28.5524554 L46,58.3616071 L46,130.422075 C46,131.828577 45.5058824,133.190379 44.6039514,134.269624 L43.8398135,135.183986 C42.7108177,136.534935 41.0408684,137.315674 39.2802734,137.315674 C37.5295011,137.315674 35.878918,136.499049 34.8167004,135.107324 L34.1328211,134.211301 C33.3346843,133.165577 32.9023437,131.886527 32.9023437,130.571019 L32.9023437,58.3616071 L32.9023437,28.5524554 L32.9023437,1 L32.9023437,1 L39.4511719,1 Z"
      ],
      duration: 500,
      easing: "easeInOutQuad"
    }
  })
  .add({
    targets: ".smallarrow",
    opacity: {
      value: ["0", "1"],
      duration: 5,
      easing: "easeInOutQuad"
    }
  })
  .add({
    targets: ".smallarrow",
    d: {
      value: [
        "M41.0599976,127.317749 C42.1177603,127.820931 42.3780136,127.943759 42.9667969,128.701416 C43.5555801,129.459073 43.5644531,129.651774 43.5644531,130.540527 C43.5644531,131.429281 43.6732527,131.325683 42.9667969,132.315613 C42.260341,133.305543 42.3669674,133.594128 41.0599976,134.049927 C39.7530277,134.505725 39.1986931,134.464759 37.9937744,134.049927 C36.7888557,133.635095 36.5650711,133.07086 36.022522,132.315613 C35.4799729,131.560366 35.4727173,131.459046 35.4727173,130.540527 C35.4727173,129.622008 35.3880525,129.551184 36.022522,128.701416 C36.6569915,127.851648 37.179404,127.649512 37.8789673,127.317749 C38.5785306,126.985986 38.7194529,127.052246 39.5,127.052246 C40.2805471,127.052246 40.0022348,126.814567 41.0599976,127.317749 Z",
        "M40.0802866,122.347015 L45.9994466,116.307 L45.9994466,116.307 L71.6611652,90.6464466 L80.8535534,99.8388348 L39.8413601,140.851028 L39.5,141.22998 L39.1586399,140.851028 L-1.85355339,99.8388348 L7.33883476,90.6464466 L32.9994466,116.307 L38.9196915,122.347037 C39.0725215,122.502959 39.2816681,122.59082 39.5,122.59082 C39.7183297,122.59082 39.9274721,122.50295 40.0802866,122.347015 Z"
      ],
      duration: 300,
      easing: "easeInOutQuad"
    }
  })
  .add({
    targets: "#arrow",
    translateY: {
      value: [0, 10],
      duration: 200,
      easing: "easeInOutQuad"
    },
    translateX: {
      value: ['-50%', '-50%'],
      duration: 200,
      easing: "easeInOutQuad"
    }
  })
  .add({
    targets: "#arrow",
    translateY: {
      value: [10, 0],
      duration: 200,
      easing: "easeInOutQuad"
    },
    translateX: {
      value: ['-50%', '-50%'],
      duration: 200,
      easing: "easeInOutQuad"
    }
  })
  .add({
    targets: "#arrow",
    translateY: {
      value: [0, 0],
      duration: 3000,
      easing: "easeInOutQuad"
    },
    translateX: {
      value: ['-50%', '-50%'],
      duration: 200,
      easing: "easeInOutQuad"
    }
  })
;
};

setArrow();


scrollerTop.addEventListener('click', function(){
  smoothScrollTo(0, 0, 6000);
});

function smoothScrollTo(endX, endY, duration) {
  const startX = window.scrollX || window.pageXOffset;
  const startY = window.scrollY || window.pageYOffset;
  const distanceX = endX - startX;
  const distanceY = endY - startY;
  const startTime = new Date().getTime();

  duration = typeof duration !== 'undefined' ? duration : 400;

  // Easing function
  const easeInOutQuart = (time, from, distance, duration) => {
    if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
    return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
  };

  const timer = setInterval(() => {
    const time = new Date().getTime() - startTime;
    const newX = easeInOutQuart(time, startX, distanceX, duration);
    const newY = easeInOutQuart(time, startY, distanceY, duration);
    if (time >= duration) {
      clearInterval(timer);
    }
    window.scroll(newX, newY);
  }, 1000 / 20); // 60 fps
};